import React from 'react'
import Joblisting from './jobscomponent/Joblisting';

import CareerwhychoosusTitle from './careerscomponent/CareerwhychoosusTitle';
import WhyChoseusList from './careerscomponent/WhyChoseusList';
// import LifeWithHsadTitle from './careerscomponent/LifeWithHsadTitle';
// import Gallery from './careerscomponent/Gallery';
// import OurGreatestStrengthtitle from './careerscomponent/OurGreatestStrengthtitle';
// import OurStrengthMainImage from './careerscomponent/OurStrengthMainImage';
import { Helmet } from 'react-helmet';

//import Joblist from './jobscomponent/Joblist'
export default function Careers() {
    // const ServicesRef = useRef(null);
  
    // const gotoServices = () =>
    //   window.scrollTo({
    //     top: ServicesRef.current.offsetTop,
    //     behavior: "smooth",
    //     // You can also assign value "auto"
    //     // to the behavior parameter.
    //   });
  return (
    <>
    <Helmet>
    <title>Best Creative Agency Delhi, Brand Advertising Agency Delhi | HSAD India | Careers</title>
    <meta name="description"content="HS Ad best Advertising, Creative agency in Delhi India provides integrated marketing services to build a brand with Digital Media, ATL / BTL and PR mediums around the Globe."/>
    <meta name="keywords" content="" />
    </Helmet>
         {/* <div className="section aboutme" data-anchor="aboutme">
	    <div className="banner-con ">
      <h1 style={{color:"white"}} className="animated fadeInDown">Join Our Team</h1>
      <p className="animated fadeInDown">Careers</p>
      <a  href="#posts" className="banner-btn">View Open Positions</a>
    </div>
    <i className="fa fa-chevron-down fa-3x bounce" onClick={gotoServices}></i>

	  <img src="assets/images/banner-career.jpg" className='img-d' alt=""/>
	  <img src="assets/images/banner-career-m.jpg" className='img-m' alt=""/> 
   
     </div> */}
       {/* <section className="careerContainer-sec" ref={ServicesRef}></section> */}
     <section className="careerContainer-sec" >

     <div className="container">
         <CareerwhychoosusTitle></CareerwhychoosusTitle>

  </div>
	<div className="container">
    <div className="row justify-content-center">
     <div className="col-md-10 offset-md-1">
      <div className="row">
        <WhyChoseusList></WhyChoseusList>
    </div> 
    </div>
	</div>
	</div>
	
	</section>
	{/* <section className="life-hsad-sec">
    <div className="container">
  <LifeWithHsadTitle></LifeWithHsadTitle>
    </div>


 <div id="#gallery">
<div id="gallery" className="container-fluid">  
<Gallery></Gallery>
                    </div>

</div> 

  </section> */}
	{/* <section className="strengt-sec">
    <div className="container">
     <OurGreatestStrengthtitle></OurGreatestStrengthtitle>
      </div>
<div className="container">
  <div className="row">
    <div className="col-md-12">
   <OurStrengthMainImage></OurStrengthMainImage>

    </div>
  </div>
</div>

  </section> */}

  
{/* <section  className="job-list-sec" id="posts">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="list-hearder" >
            <div className="list-hd-title">Job Title</div>
            <div className="list-hd-loc">Location</div>
            <div className="list-hd-join">Join Us</div>
        </div>
       <Joblisting/>
      </div>

    </div>

  </div>


</section> */}





    </>
  )
}
