import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
export default function PartnersSec() {
	const responsive = {
		superLargeDesktop: {
		  // the naming can be any, depends on you.
		  breakpoint: { max: 4000, min: 3000 },
		  items: 4
		},
		desktop: {
		  breakpoint: { max: 3000, min: 1024 },
		  items: 3,
		  slidesToSlide:1
		
		},
		tablet: {
		  breakpoint: { max: 1024, min: 464 },
		  items: 2
		},
		mobile: {
		  breakpoint: { max: 464, min: 0 },
		  items: 2
		}
	  };
	
  return (
    <>

<section className="clint-sec">
	<div className="container">
	<div className="row"><div className="col-md-12"><h2 className="title-h">OUR PARTNERS</h2></div></div>
	</div>

	   
		<div className="clint-box-bg">
			<div className="container">
				<div className="row">
                 <div className="col-md-7">

				 <Carousel className="clint-slider" responsive={responsive}  draggable={true}showDots={false} autoPlay={true} arrows={false}infinite={true} >
				   <div className='itam'><div className='clint-logo-box'><img src="assets/images/clint/lg-logo.png" alt=""/></div></div>
                   <div className='itam'><div className='clint-logo-box'><img src="assets/images/clint/rg-logo.png" alt=""/></div></div>
                   <div className='itam'><div className='clint-logo-box'><img src="assets/images/clint/gir-logo.png" alt=""/></div></div>
				   <div className='itam'><div className='clint-logo-box'><img src="assets/images/clint/car-logo.png" alt=""/></div></div>
				   <div className='itam'><div className='clint-logo-box'><img src="assets/images/clint/lg-display-logo.png" alt=""/></div></div>
				   <div className='itam'><div className='clint-logo-box'><img src="assets/images/clint/kcc-logo.png" alt=""/></div></div>
				   <div className='itam'><div className='clint-logo-box'><img src="assets/images/clint/kto-logo.png" alt=""/></div></div>
				   <div className='itam'><div className='clint-logo-box'><img src="assets/images/clint/del-monte-logo.png" alt=""/></div></div>
				   <div className='itam'><div className='clint-logo-box'><img src="assets/images/clint/lg-cns-logo.png" alt=""/></div></div>
				   <div className='itam'><div className='clint-logo-box'><img src="assets/images/clint/resmed.png" alt=""/></div></div>
				   
				 </Carousel>

                  
                 {/* <div id="ourpartner" className="owl-carousel owl-theme clints">
                   <div className='itam'><div className='clint-logo-box'><img src="assets/images/clint/lg-logo.png" alt=""/></div></div>
                   <div className='itam'><div className='clint-logo-box'><img src="assets/images/clint/royal-green-logo.png" alt=""/></div></div>
                   <div className='itam'><div className='clint-logo-box'><img src="assets/images/clint/gir-logo.png" alt=""/></div></div>
                   <div className='itam'><div className='clint-logo-box'><img src="assets/images/clint/car-logo.png" alt=""/></div></div>
                  </div> */}




					</div>
				<div className="col-md-5 position-relative" >
				      <div className="num-box ">
					    
						<span>
						<h2>110+</h2> 
						<h3>AWARDS</h3>
						<p>We believe that collaboration and synergy with esteemed partners are the keys to true success. Together, we forge strong alliances that amplify creativity and deliver exceptional results.</p>
						</span>
					</div>
					</div>
					
			 </div>
		</div>
		
		</div>	
	</section>
    </>
  )
}
